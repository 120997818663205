<template>
	<div
		class="bullets"
		:class="[bulletsDirection ? bulletsDirection : '']"
	>
		<template v-if="isMobile">
			<div
				class="bullet-number text-2xs font-bold font-body"
				v-for="index in bCount"
				:key="index"
				:class="[index == bActiveIndex ? [activeColorClass, 'isActive'] : '']"
				@click="onBulletClick(index)"
			>{{ twoDigits(index) }}
			</div>
		</template>
		<template v-else>
			<div
				class="bullet"
				v-for="index in bCount"
				:key="index"
				:class="[index == bActiveIndex ? activeColorClass : '']"
				@click="onBulletClick(index)"
			>
			</div>
		</template>
	</div>
</template>

<script>
import { ref, watchEffect, computed } from "vue";
import { useStore } from "vuex";
export default {
	props: {
		count: Number,
		activeIndex: Number,
		direction: String,
		color: String,
	},
	setup(props, context) {
		const store = useStore();
		const bCount = ref(0);
		const bActiveIndex = ref(0);
		const activeColorClass = ref("");
		const bulletsDirection = ref("");
		const isMobile = computed(() => store.state.isMobile);

		watchEffect(() => {
			bCount.value = props.count;
			bActiveIndex.value = props.activeIndex + 1;
			activeColorClass.value = `bg-${props.color}`;
			bulletsDirection.value = `bullets-${props.direction}`;
		});

		const onBulletClick = (index) => {
			bActiveIndex.value = index;
			context.emit("bulletIndex", index - 1);
		};

		const twoDigits = (index) => {
			if (index >= 1 && index <= 9) {
				return "0" + index;
			} else {
				return index;
			}
		};

		// colors defined in variables.scss

		return {
			isMobile,
			bCount,
			bActiveIndex,
			onBulletClick,
			activeColorClass,
			bulletsDirection,
			twoDigits,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";

.bullets-row {
	flex-direction: row;
}
.bullets-column {
	flex-direction: column;
	.bullet {
		margin-top: 3px;
		margin-bottom: 3px;
	}
}

.bullet {
	background: $white;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid $about;
	margin-left: 5px;
	margin-right: 5px;
	cursor: pointer;
	&-number {
		margin-left: 12px;
		margin-right: 12px;
		color: $gray;
		line-height: 2;
		&.isActive {
			background: transparent !important;
			border-bottom: 2px solid $about;
			color: $secondary;
		}
	}
}
.bg-about {
	background: $about !important;
}
.bg-secondary {
	background: $secondary !important;
}
.bg-default {
	background: $default !important;
}
.bg-white {
	background: $white !important;
}
.bg-orange {
	background: $orange !important;
}
.bg-red {
	background: $red !important;
}
.bg-purple {
	background: $purple !important;
}
.bg-red2 {
	background: $red2 !important;
}
.bg-red3 {
	background: $red3 !important;
}
.bg-green {
	background: $green !important;
}
.bg-yellow {
	background: $yellow !important;
}
.bg-teal {
	background: $teal !important;
}
</style>